import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  server_post_data,
  get_blog_details_url,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
function Blogdetail() {
  const [showLoader, setShowLoader] = useState(false);
  const [VacancytData, setVacancytData] = useState([]);
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);

  const handleFetchData = async () => {
    setShowLoader(true);
    var form_data = new FormData();
    form_data.append("current_url", "/" + currentUrl);
    await server_post_data(get_blog_details_url, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          if (Response.data.message.data_blog.length > 0) {
            setVacancytData(Response.data.message.data_blog[0]);
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  return (
    <div className="blogPost">
      <div className={showLoader ? "loading" : ""}></div>
      <Header />
      <div className="blogPostcontainer container-lg">
        <h1 className="blogPostHeading">{VacancytData.title_name}</h1>
        <div className="row mx-0 blogPosthEADcONTAINER">
          <div className="col-md-3 blogheadImg">
            <img
              src={VacancytData.image_url}
              alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
            />
          </div>
          <div className="col-md-8 m-auto">
            <h4>Introduction</h4>
            <p>{VacancytData.tag_line}</p>
          </div>
        </div>
        <div className="blogpostContent blog_show_image_show">
          {" "}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(VacancytData.description),
            }}
          />{" "}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogdetail;
