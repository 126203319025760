window.addEventListener("scroll", () => {
    try {
        var icons = document.getElementById('social_icons_scrolling');
        var y = window.pageYOffset;
        if (y >= (document.getElementById('footer_icons_hide').offsetTop)) {
            icons.style.cssText = `
            z-index:-10;opacity:0;
        `
        }

        else if (y < (document.getElementById('footer_icons_hide').offsetTop)) {
            icons.style.cssText = `
        z-index:10;
        `
        }
    } catch (err) {
        //err
    }
});


window.addEventListener("scroll", () => {
    try {
        var icons = document.getElementById('bottom_btn');
        var y = window.pageYOffset;
        // console.log(y + ">=" + document.getElementById('hide_bottom_btn').offsetTop);
        if (y >= (document.getElementById('hide_bottom_btn').offsetTop)) {
            icons.style.cssText = "z-index:-10;opacity:0"
            // console.log("1");
        }

        else if (y < (document.getElementById('hide_bottom_btn').offsetTop)) {
            icons.style.cssText = "z-index:10;"
            // console.log("2");
        }
    } catch (err) {
        //err
    }
});
