import axios from "axios";
const appauth_key = "cjR4Y3hGTHlpLzcwQ2pjUG9CNWFNUT09";

let APL_LINK = "http://192.168.1.39:8000/";
APL_LINK = "https://back.barleys.se/";
let local_server_link_back = APL_LINK + "api/admin_link/";
let local_server_link_website = APL_LINK + "api/website_link/";

/** new backend */
const get_all_timing_date_wise =
  local_server_link_back + "get_all_timing_date_wise";

const create_table_reservation_website =
  local_server_link_website + "create_table_reservation_website";
const get_blog_data = local_server_link_website + "get_blog_data_website";
const seo_data_url = local_server_link_website + "get_seo_data_website";
const get_blog_details_url = local_server_link_website + "blog_details_website";
const get_designation = local_server_link_website + "get_designation_website";
const save_career_data = local_server_link_website + "save_Career";
const get_event_data = local_server_link_website + "get_event_data_website";
const get_team = local_server_link_website + "get_team_website";
const footer_data = local_server_link_website + "get_website_data";
/** new backend */
const server_post_data = async (url_for, form_data) => {
  // const headers = {
  //   "Content-Type": "application/json",
  // };

  if (form_data === null) {
    form_data = new FormData();
  }
  form_data.append("admin_id", "0");
  form_data.append("default_restaurant_id", "1");
  form_data.append("appauth_key", appauth_key);
  return axios.post(url_for, form_data);
};

export {
  appauth_key,
  footer_data,
  get_designation,
  get_team,
  save_career_data,
  get_event_data,
  server_post_data,
  get_blog_data,
  seo_data_url,
  get_blog_details_url,
  get_all_timing_date_wise,
  create_table_reservation_website,
};
