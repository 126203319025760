import React from "react";
import "../Css/PrivacyPolicy.css";
function TermsOfUse() {
  return (
    <div className="privacy_policy">
      <div className="container privacy_policy_wrapper">
        <div className="privacy_policy_container">
          <div>
            <strong>
              <h6 style={{ marginTop: "1rem" }}>1. Introduction</h6>
            </strong>
            <p>
              Welcome to Barley’s Food factory website (the "Website"). By using
              our Website, you agree to comply with and be bound by these Terms
              of Use. Please read these Terms of Use carefully before using our
              Website.
              <br />
              “You” and “your” mean you as the user of the Website
            </p>
          </div>
          <div>
            <strong>
              <h6>2. Acceptance of the Terms of Use</h6>
            </strong>
            <p>
              <strong style={{ color: "black" }}>2.1.</strong>&nbsp;By accessing
              the Barley's Food Factory website, you agree to abide by these
              Terms of Use.
            </p>
            <p>
              <strong style={{ color: "black" }}>2.2.</strong>&nbsp;If you do
              not agree to these Terms of Use, please refrain from using the
              Barley's Food Factory website. It is important to review all the
              Terms of Use before using the website. You are welcome to save or
              print a copy of these Terms of Use for future reference.
            </p>
            <p>
              <strong style={{ color: "black" }}>2.3.</strong>&nbsp;These Terms
              of Use, along with Barley's Food Factory own terms and conditions,
              form the complete agreement between you and Barley'sFood Factory .
              If you have any questions or concerns regarding these terms,
              please contact us via email or mail using the address provided at
              the end of these Terms of Use. We will make every effort to
              address your queries promptly.
            </p>
          </div>
          <div>
            <strong>
              <h6>3. Accessing Our Website</h6>
            </strong>
            <p>
              <strong style={{ color: "black" }}>3.1.</strong>&nbsp;Access to
              Barley's Food Factory website is provided on a temporary basis,
              and we reserve the right to modify or withdraw any part of the
              website without prior notice. Periodic suspensions of access may
              occur for emergency maintenance or other reasons. We also reserve
              the discretion to correct errors, update features, or remove
              content as needed.
            </p>
            <p>
              <strong style={{ color: "black" }}>3.2.</strong>&nbsp;Please note
              that access to our website depends on the availability of the
              world wide web, and we are not responsible for any inability to
              access the website due to circumstances beyond our control.
            </p>
            <p>
              <strong style={{ color: "black" }}>3.3.</strong>&nbsp;It's
              important to acknowledge the inherent risks of internet usage,
              including data transmission security concerns. By accessing our
              website, you affirm that you have the necessary expertise and
              resources to use it securely.
            </p>
            <p>
              <strong style={{ color: "black" }}>3.3.</strong>&nbsp;You are
              responsible for ensuring that anyone accessing the website through
              your internet connection is aware of and complies with these Terms
              of Use.
            </p>
            <p>
              <strong style={{ color: "black" }}>3.3.</strong>&nbsp;We retain
              the right to terminate your access to our website if we believe
              you have violated any provisions of the Terms of Use or other
              applicable policies.
            </p>
          </div>
          <div>
            <strong>
              <h6>4. Acceptable Use Policy</h6>
            </strong>
            <p>
              When using Barley's Food Factory website, you agree to abide by
              these Terms of Use and all relevant laws. Specifically, unless
              expressly permitted by us in writing, you may not:
            </p>
            <ul>
              <li>
                <p>
                  Use our website in an unlawful, fraudulent manner, or for
                  purposes of collecting personally identifiable information or
                  impersonating other users.
                </p>
              </li>
              <li>
                <p>Interfere with the security features of our website.</p>
              </li>
              <li>
                <p>
                  Attempt to decompile, reverse engineer, or disassemble any
                  part of our website.
                </p>
              </li>
              <li>
                <p>
                  Use automated tools or manual processes to monitor or copy our
                  web pages or content, or extract usage data.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <div>
              <strong>
                <h6>5. Services Offered</h6>
              </strong>
            </div>
            <p>
              <strong style={{ color: "black" }}>5.1 Table Booking:</strong>
              &nbsp;
            </p>
            <ul>
              <li>
                <p>
                  Barley's Food Factory offers a booking service directly on our
                  website. When you make a booking through our website, you are
                  entering into a direct contract with Barley’s Food Factory.
                </p>
              </li>
              <li>
                <p>
                  To make a booking, you must have the legal capacity to do so
                  and accept financial responsibility for all transactions under
                  your name. Ensure that all information provided is accurate.
                  Payment for your meal is typically made directly to the
                  restaurant after dining, unless otherwise specified during
                  booking.
                </p>
              </li>
              <li>
                <p>
                  Your booking is an offer to Barley's Food Factory. It becomes
                  legally binding upon receiving a confirmatory email accepting
                  your booking. Please arrive on time and bring a copy of your
                  email booking confirmation. If prepayment is required, bring
                  the credit/debit card used for booking.
                </p>
              </li>
              <li>
                <p>
                  To cancel a booking, contact Barley's Food Factory directly.
                  Contact details and any applicable cancellation policy will be
                  in the confirmation email.
                </p>
              </li>
              <li>
                <p>
                  Some policies, like 'no-show' fees or prepayments, may apply.
                  Your credit card details may be securely stored for such
                  cases, as per our Privacy Policy. Disputes regarding fees
                  should be addressed to us for resolution.
                </p>
              </li>
              <li>
                <p>
                  Barley's Food Factory reserves the right to cancel bookings
                  without liability on your part.
                </p>
              </li>
              <li>
                <p>
                  For any queries or concerns regarding your booking, contact
                  Barley's Food factory directly using the details provided in
                  the confirmation email.
                </p>
              </li>
            </ul>
          </div>

          <div>
            <strong>
              <h6>6. User Responsibilities</h6>
            </strong>
            <p>
              <strong style={{ color: "black" }}>6.1.</strong>&nbsp;These “Terms
              of Use” set out the terms of use on which you may make use of our
              Website and our Application, and the services we provide. In
              addition, certain features in relation with the Website and the
              Application are governed by separate, specific terms and
              conditions, as follows:
            </p>
            <ul>
              <li>
                <p>
                  Any personal information you provide on our Website, such as
                  your name, email address, and contact details, is governed by
                  our Privacy Policy. Please refer to our Privacy Policy for
                  details on how we process your personal information and your
                  rights.
                </p>
              </li>
              <li>
                <p>
                  When you use our Website, we may collect information through
                  cookies to enhance your experience and distinguish you from
                  other users. Our Cookie Policy explains our use of cookies and
                  the purposes for which we use them.
                </p>
              </li>
              <li>
                <p>
                  You must be of 18 years or above to use our Website and
                  services.
                </p>
              </li>
              <li>
                <p>
                  You agree to provide accurate and current information when
                  booking tables or placing orders.
                </p>
              </li>
              <li>
                <p>
                  You agree not to use our Website for any unlawful or
                  fraudulent purposes.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <strong>
              <h6>7. Limitation of Liability</h6>
            </strong>
            <p>
              Barley’s Food Factory is not liable for any damages or losses
              arising from the use of our Website or services, including but not
              limited to, delivery delays, errors in orders, or technical
              issues.
            </p>
          </div>
          <div>
            <strong>
              <h6>8. Intellectual Property</h6>
            </strong>
            <p>
              All content on our Website, including text, images, logos, and
              trademarks, is the property of Barley’s Food Factory and is
              protected by copyright and other intellectual property laws. You
              may not use, reproduce, or distribute any content from our Website
              without our permission.
            </p>
          </div>
          <div>
            <strong>
              <h6>9.Changes to Terms</h6>
            </strong>
            <p>
              Barley’s Food Factory Restaurant reserves the right to modify or
              update these Terms of Use at any time. Changes will be effective
              upon posting on our Website. It is your responsibility to review
              these Terms periodically for any updates.
            </p>
          </div>
          <div>
            <strong>
              <h6>10. Governing Law</h6>
            </strong>
            <p>
              <strong style={{ color: "black" }}>10.1.</strong>&nbsp;These Terms
              of Use are governed by the laws of Sweden.
            </p>
            <p>
              <strong style={{ color: "black" }}>10.2.</strong>&nbsp;Any
              disputes arising from these Terms shall be subject to the
              exclusive jurisdiction of the courts in Gothenburg.
            </p>
            <p>
              <strong style={{ color: "black" }}>10.3.</strong>&nbsp;By using
              our Website and services, you agree to these Terms of Use. If you
              do not agree with any part of these terms, please refrain from
              using our Website.
            </p>
          </div>
          <div>
            <strong>
              <h6>11. Contact Information</h6>
            </strong>
            <p>
              f you have any questions about these terms and conditions, please
              contact us via <br />{" "}
              <strong style={{ color: "#212121" }}>
                Email: boka@barleys.se
              </strong>{" "}
              <br />{" "}
              <strong style={{ color: "#212121" }}>Tel: +4631515748</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
