import React, { useEffect, useState } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollOnTop from "./scrollOnTop";
import {
  server_post_data,
  seo_data_url,
} from "./ServiceConnection/serviceconnection.js";
import BookingHeader from "./Components/HeaderBookingPage";
import HomePage from "./Components/HomePage";
import TeamPage from "./Components/TeamPage";
import Blog from "./Components/Blog";
import Test from "./Components/Test";
import Careers from "./Components/Careers";
import Blogdetail from "./Components/Blogdetail";
import PageNotFound from "./Components/404";
// const HomePage = lazy(() => import("./Components/HomePage"));
// const TeamPage = lazy(() => import("./Components/TeamPage"));
// const Blog = lazy(() => import("./Components/Blog"));
// const Test = lazy(() => import("./Components/Test"));
// const Careers = lazy(() => import("./Components/Careers"));
// const Blogdetail = lazy(() => import("./Components/Blogdetail"));
// const PageNotFound = lazy(() => import("./Components/404"));

function App() {
  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/booking") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name === "/team") {
      data_seo_link_final = pretty_function_name + "~@~3";
    } else if (call_function_name === "/careers") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name === "/blog") {
      data_seo_link_final = pretty_function_name + "~@~5";
    } else if (call_function_name.includes("blog/blog_detail")) {
      data_seo_link_final = pretty_function_name + "~@~6";
    }
    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";

    if (page_number === "1") {
      data_seo_link_final = <HomePage />;
    } else if (page_number === "2") {
      data_seo_link_final = <BookingHeader />;
    } else if (page_number === "3") {
      data_seo_link_final = <TeamPage />;
    } else if (page_number === "4") {
      data_seo_link_final = <Careers />;
    } else if (page_number === "5") {
      data_seo_link_final = <Blog />;
    } else if (page_number === "6") {
      data_seo_link_final = <Blogdetail />;
    }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    var form_data = new FormData();

    await server_post_data(seo_data_url, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setSEOloop(Response.data.message.seo_loop);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollOnTop />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/booking" element={<BookingHeader />} />
          <Route path="/team" element={<TeamPage />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/test" element={<Test />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog/blog_detail/:id" element={<Blogdetail />}></Route>

          {SEOloop.map((data, index) => {
            const for_loop_come = match_and_return_seo_link_and_page(
              data.call_function_name,
              data.pretty_function_name,
              data.title_name,
              data.favicon
            );
            const stringArray = for_loop_come.split("~@~");

            return (
              <Route
                key={index}
                path={stringArray[0]}
                element={get_page_name(stringArray[1])}
              />
            );
          })}
          <Route path="/*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
