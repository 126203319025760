import React, { useState, useEffect, useRef } from "react";
import "../Css/CareerPageCss.css";
import Header from "./Header";
import Footer from "./Footer";
import { Modal } from "react-bootstrap";
import {
  get_designation,
  save_career_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
import ModelPopUp from "./ModelPopUp";
import {
  check_vaild_save,
  combiled_form_data,
  getRandomSixLetterString,
  empty_form,
} from "../CommonJquery/CommonJquery.js";
import PrivacyPolicy from "./PrivacyPolicy.js";
let captch_check = "";
export default function Careers() {
  const fileInputRef = useRef(null);
  const [namess, setData1] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [userInputCpt, setUserInputCpt] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showPrivacy, setshowPrivacy] = useState(false);
  const [inputFilled, setInputFilled] = useState(false);

  const handlePrivacy = () => {
    setshowPrivacy(true);
  };

  const handlePrivacyClose = () => {
    setshowPrivacy(false);
  };

  const fetchData = async () => {
    setShowLoader(true);
    var form_data = new FormData();
    await server_post_data(get_designation, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          if (Response.data.message.data_blog.length > 0) {
            setData1(Response.data.message.data_blog);
          }
          if (captch_check === "") {
            captch_check = getRandomSixLetterString();
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      if (userInputCpt === captch_check) {
        setShowLoader(true);
        let fd_from = combiled_form_data(form_data, dynaicimage);
        await server_post_data(save_career_data, fd_from)
          .then((Response) => {
            if (Response.data.error) {
              alert(Response.data.message);
            } else {
              empty_form(form_data);
              captch_check = getRandomSixLetterString();
              call_model_function("title", Response.data.message);

              fileInputRef.current.value = null;
            }
            setShowLoader(false);
          })
          .catch((error) => {
            setShowLoader(false);
          });
      } else {
        setIsCaptchaValid(false);
      }
    }
  };

  const call_model_function = async (title, description) => {
    setTitle(title);
    setDescription(description);
    setShowModel(true);
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
          [keyname + "_name"]: file.name, // Add this line to store the file name
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
        [keyname + "_name"]: null, // Add this line to reset the file name
      }));
    }
  };

  // Read more button
  const careerText =
    "Join our vibrant American restaurant if you're passionate about the restaurant industry and love delicious food and drinks. Discover a dynamic atmosphere with mouthwatering beers and delectable cuisine. We offer personal and professional growth and entrepreneurial opportunities. Let's embark on a thrilling journey together and create an unforgettable experience for our guests.";

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleCheckboxChange = (event) => {
    setInputFilled(event.target.checked);
  };

  const handleSubmit = () => {
    if (!inputFilled) {
      alert("Please agree to the Privacy Policy.");
    } else {
      handleSaveChangesdynamic("CarrerformData", save_career_data);
    }
  };

  return (
    <>
      <div className={showLoader ? "loading" : ""}></div>
      <Header />
      <div className="container text-center careerHeadWrapper">
        <h4>Want to be the part of us, most welcome!</h4>
        <p className="d-none d-sm-block">
          Join our vibrant American restaurant if you're passionate about the
          restaurant industry and love delicious food and drinks. Discover a
          dynamic atmosphere with mouthwatering beers and delectable cuisine. We
          offer personal and professional growth and entrepreneurial
          opportunities. Let's embark on a thrilling journey together and create
          an unforgettable experience for our guests.
        </p>
        <div className="p-1 d-sm-none read_more_text">
          {showFullText ? careerText : careerText.slice(0, 153)}
          <br />
          <button className="read_more_career shadow" onClick={toggleText}>
            {showFullText ? "Read less" : "Read more"}
          </button>
        </div>
      </div>

      <div className="container text-center applyNowWrapper">
        <h1>Apply Now</h1>

        <div className="applyInputHead col-md-10 m-auto">
          <form id="CarrerformData">
            <div className="row">
              <div className="input_margin col-md-5 col-11">
                <input
                  id="user_name"
                  name="user_name"
                  className="apply_1 trio_mendate"
                  type="text"
                  placeholder="Name"
                  maxLength="50"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/^\s+/, "")
                      .replace(/[^A-Za-z\s]/g, "");
                  }}
                />
              </div>
              <div className="input_margin col-md-5 col-11">
                <input
                  id="job_email"
                  name="job_email"
                  maxLength="100"
                  onInput={(e) => {
                    if (!/^[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                      e.target.value = ""; // Clear the input if it contains any other character
                    }
                  }}
                  className="col-md-5 apply_2 trio_email trio_mendate"
                  type="text"
                  placeholder="Email"
                />
              </div>
              <div className="input_margin col-md-5 col-11">
                <input
                  name="job_mobile_no"
                  id="job_mobile_no"
                  className="apply_3  trio_no trio_mendate"
                  type="text"
                  placeholder="Contact No."
                  maxLength="15"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/^\s+/, "")
                      .replace(/[^0-9]/g, "");
                  }}
                />
              </div>
              <div
                className="input_margin col-md-5 col-11"
                id="footer_icons_hide"
              >
                <input
                  name="job_message"
                  id="job_message"
                  maxLength="100"
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/^\s+/, "")
                      .replace(/[^A-Za-z\s]/g, "");
                  }}
                  className="apply_4 trio_mendate"
                  type="text"
                  placeholder="Skills"
                />
              </div>
              <div className="row resumeDiv m-auto">
                <div className="col-md-5 p-0 m-auto">
                  <label>Select Post For Apply</label>
                  <select
                    name="vacancy_id"
                    id="vacancy_id"
                    className="mt-0 apply_5 trio_mendate"
                  >
                    <option value={""}>Select Post</option>
                    {namess.map((item, index) => (
                      <option value={item.primary_id} key={index}>
                        {item.designation}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-5 p-0 m-auto">
                  <label className="col-md-5">Select Your Resume</label>
                  <div className="input_margin">
                    <input
                      name="file_attachment"
                      id="file_attachment"
                      accept=".pdf,.doc,.docx"
                      ref={fileInputRef}
                      onChange={handleFileChangedynamic("file_attachment")}
                      className="input_file col-md-5 mt-0 apply_6 text-center trio_mendate"
                      type="file"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-5 p-0 m-auto">
                  <label className="col-md-5">
                    Enter Captcha{" "}
                    <span className="color_red_bold captch_select">
                      {captch_check}
                    </span>
                  </label>
                  <div className="input_margin">
                    <input
                      type="text"
                      className="form-control trio_mendate mt-0"
                      id="captcha"
                      name="captcha"
                      onChange={(e) => {
                        setUserInputCpt(e.target.value);
                      }}
                      required
                    />
                    {isCaptchaValid ? (
                      <p></p>
                    ) : (
                      <p className="text-danger mt-1 text-center">
                        Invalid CAPTCHA .
                      </p>
                    )}
                  </div>
                  <div className="Chckbxxx mb-3">
                    <input
                      type="checkbox"
                      name="checkSurfaceEnvironment-1"
                      id="checkSurfaceEnvironment-1"
                      onChange={handleCheckboxChange}
                      style={{
                        width: "fit-content",
                        margin: "0.5rem",
                        marginTop: "0.3rem",
                      }}
                    />
                    <label
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        width: "fit-content",
                        paddingLeft: "0.5rem",
                      }}
                      htmlFor="checkSurfaceEnvironment-1"
                    >
                      I agree to the{" "}
                    </label>
                    <span
                      onClick={handlePrivacy}
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      &nbsp;Privacy Policy
                    </span>
                  </div>
                </div>
                <div className="col-md-5 p-0 m-auto"></div>
              </div>

              <div className="col-md-10 col-11 p-0 mx-auto">
                {/* <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() =>
                    handleSaveChangesdynamic("CarrerformData", save_career_data)
                  }
                >
                  Apply Now
                </button> */}
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit}
                >
                  Apply Now
                </button>
              </div>
            </div>
            {showModel && <ModelPopUp title={title} dis={description} />}
          </form>
        </div>
        <br />
        <br />
        <br />
        <br />
        <Modal size="xl" show={showPrivacy} onHide={handlePrivacyClose}>
          <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable m-0">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Privacy Policy
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handlePrivacyClose}
                ></button>
              </div>
              <div className="modal-body">
                <PrivacyPolicy />
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Footer />
    </>
  );
}
