import React, { useState, useEffect } from "react";
import Meta from "../Assets/images/meta.svg";
import Twitter from "../Assets/images/twitter.svg";
import Insta from "../Assets/images/insta.svg";
import LinkedIn from "../Assets/images/linkedIn.svg";
import Snapchat from "../Assets/images/snapchat.png";
import "../SrollIcons.js";
import { Link } from "react-router-dom";
import {
  footer_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
const logo = require("../Assets/logo/barleys_new_logo.png");
function Header() {
  const [data, setData] = useState([]);
  const [imagelink, setimagelink] = useState([]);
  const [takeaway_url, settakeaway_url] = useState([]);
  const [tableorder_url, settableorder_url] = useState([]);
  const [delivery_url, setdelivery_url] = useState([]);

  const handleFetchData = async () => {
    var form_data = new FormData();
    await server_post_data(footer_data, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          if (Response.data.message.footer_data.length > 0) {
            setData(Response.data.message.footer_data[0]);
          }


          setimagelink(Response.data.message.menu_file);
          settakeaway_url(Response.data.message.takeaway_url);
          settableorder_url(Response.data.message.tableorder_url);
          setdelivery_url(Response.data.message.delivery_url);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <header className="header home_page_header">
        <div className="nav_wrapper">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand small_logo d-lg-none" href="/">
              <img
                src={logo}
                alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
              />
            </a>
            <button
              className="navbar-toggler nav_mbl_btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse nav_right"
              id="navbarNavAltMarkup"
            >
              <ul className="navbarNavMainList">
                <ul className="navbar-nav newNav">
                  <li className="nav-item">
                    <Link
                      className="nav-link nowrap"
                      onClick={() => handleLinkClick("/")}
                    >
                      Events
                    </Link>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      target="_blank"
                      rel="noreferrer"
                      href={delivery_url}
                    >
                      Delivery
                    </a>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link nowrap"
                      onClick={() => handleLinkClick("/booking")}
                    >
                      Book a table
                    </Link>
                  </li>
                </ul>

                <a
                  className="navbar-brand logoHideInTab logo d-none d-lg-block"
                  href="/"
                >
                  <img
                    src={logo}
                    alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  />
                </a>

                <ul className="navbar-nav newNav">
                  <li className="nav-item">
                    <a
                      className="nav-link nowrap"
                      target="_blank"
                      rel="noreferrer"
                      href={tableorder_url}
                    >
                      Table order
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      target="_blank"
                      rel="noreferrer"
                      href={takeaway_url}
                    >
                      Takeaway
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={imagelink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Menu
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </nav>
        </div>

        <div className="welcome_wrapper position-relative">
          <div className="social-icons" id="social_icons_scrolling">
            <ul>
              <li>
                <a href={data.facebook_link} target="_blank">
                  <img src={Meta} alt="facebook" />
                </a>
              </li>
              <li>
                <a href={data.twitter_link} target="_blank">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a href={data.instragram_link} target="_blank">
                  <img src={Insta} alt="instagram" />
                </a>
              </li>
              <li>
                <a href={data.linkedin_link} target="_blank">
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </li>
              <li>
                <a href={data.snapchat_link} target="_blank">
                  <img src={Snapchat} alt="Snapchat" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
