import React, { useState, useEffect, useRef } from "react";
import DoneGif from "../Assets/images/DoneGif.gif";
import "../Css/Modelpopup.css";
const ModelPopUp = ({ title, dis }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    openPopup();
  }, []);

  const openPopup = () => {
    if (popupRef.current) {
      popupRef.current.style.opacity = "1";
      popupRef.current.style.transform = "translate(-50%, -50%) scale(1)";
    }
    setIsOpen(true);
  };

  const closePopup = () => {
    if (popupRef.current) {
      popupRef.current.style.opacity = "0";
      popupRef.current.style.transform = "translate(-50%, -50%) scale(0.8)";
    }
    setIsOpen(false);
  };
  return (
    <div>
      <div className={`popup-overlay ${isOpen ? "open" : ""}`}>
        <div
          ref={popupRef}
          className={`pup-cntnt pup-cntnt-width ${isOpen ? "open" : ""}`}
        >
          <div className="modal-content">
            
            <div className="modal-body modal-body-color ">
              <div className="text-center mb-2">
                <img
                  src={DoneGif}
                  alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  className="model-image"
                />
              </div>
              {dis}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary closeFooterModal"
                data-dismiss="modal"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelPopUp;
