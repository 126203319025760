import React, { useState, useEffect } from "react";
import "../Css/Footer.css";
import "../Css/HomePageCss.css";
import "../Css/HomePageCss1.css";
import MetaWhite from "../Assets/images/meta_white.svg";
import Twitter from "../Assets/images/twitter.svg";
import Insta from "../Assets/images/insta.svg";
import LinkedIn from "../Assets/images/linkedIn.svg";
import Snapchat from "../Assets/images/snapchat.png";
import PhonePink from "../Assets/images/phone_pink.svg";
import PlacePink from "../Assets/images/place_pink.svg";
import EmailPink from "../Assets/images/email_pink.svg";
import InstaImgBg from "../Assets/images/instagram_img_bg.png";
import { Modal, Button } from "react-bootstrap";
import posthog from "posthog-js";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import crossIcn from "../Assets/images/vectorCrossicon.svg";
import righIcn from "../Assets/images/VectorRighy.svg";
import imgDrp from "../Assets/images/dropdown-svgrepo-com.svg";
import { ReactComponent as UpArrow } from "../Assets/images/right-arrow-svgrepo-com.svg";
import { ReactComponent as DownArrow } from "../Assets/images/down-arrow-svgrepo-com.svg";
import {
  footer_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
import PrivacyPolicy from "./PrivacyPolicy.js";
import TermsOfUse from "./TermsOfUse.js";
function Footer() {
  const [data, setData] = useState([]);
  const [phoneno, setPhoneno] = useState([]);
  const [emailcall, setemailcall] = useState([]);
  const [imagelink, setimagelink] = useState([]);
  const [takeaway_url, settakeaway_url] = useState([]);
  const [tableorder_url, settableorder_url] = useState([]);
  const [delivery_url, setdelivery_url] = useState([]);
  const [week_dates, setweek_dates] = useState([]);
  const [week_datesnew, setweek_datesnew] = useState([]);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const handleFetchData = async () => {
    var form_data = new FormData();
    await server_post_data(footer_data, form_data)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          if (Response.data.message.footer_data.length > 0) {
            setData(Response.data.message.footer_data[0]);
            setPhoneno("tel:" + Response.data.message.footer_data[0].phone_no);
            setemailcall(
              "mailto:" + Response.data.message.footer_data[0].email_id
            );
          }

          setimagelink(Response.data.message.menu_file);
          settakeaway_url(Response.data.message.takeaway_url);
          settableorder_url(Response.data.message.tableorder_url);
          setdelivery_url(Response.data.message.delivery_url);
          setweek_dates(Response.data.message.data_date);
          setweek_datesnew(Response.data.message.data_date_new);
          console.log(Response.data.message.data_date_new);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const [showSecondModal, setShowSecondModal] = useState(false);

  const [cook1Enabled, setCook1Enabled] = useState(false);
  const [cook2Enabled, setCook2Enabled] = useState(false);
  const [cook3Enabled, setCook3Enabled] = useState(false);
  const [cook4Enabled, setCook4Enabled] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [showPrivacy, setshowPrivacy] = useState(false);

  const handlePrivacy = () => {
    setshowPrivacy(true);
  };

  const handlePrivacyClose = () => {
    setshowPrivacy(false);
  };

  const send_null_value = "true";

  // Storing data
  const storeData = (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      // Handle the error
      console.log(error);
    }
  };

  // Retrieving data
  // const retrieveData = (key) => {
  //   try {
  //     const value = localStorage.getItem(key);
  //     if (value !== null) {
  //       return value;
  //     }
  //     return send_null_value;
  //   } catch (error) {
  //     return send_null_value;
  //   }
  // };
  // const retrive_data = Boolean(retrieveData("cookie_save"));
  const [showCookies, setShowCookies] = useState(true);
  const [showModal, setShowModal] = useState(false);
  let isCookie = false;
  // start cookie save
  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("cookieSettings"));
    let isCookie = false;
    if (savedSettings) {
      setCook3Enabled(savedSettings.cook3Enabled);
      isCookie = savedSettings.cook3Enabled;
    } else {
      setShowModal(true); // Modal will not be shown if settings are saved
    }
    loadGoogleAnalytics(isCookie);
  }, []);

  const loadGoogleAnalytics = (isCookie) => {
    if (isCookie) {
      console.log("Google");
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-BV0E28YBXL";
      script.async = true;
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-BV0E28YBXL");
      };
      document.body.appendChild(script);
    } else {
      Cookies.remove("_ga", { path: "/", domain: document.domain });
      Cookies.remove("_ga_BV0E28YBXL", { path: "/", domain: document.domain });
      console.log("Google Analytics cookies blocked.");
    }
  };
  //model buttons
  const handleAccept = () => {
    storeData("cookie_save", "false");
    setShowModal(false);
  };

  const handleManage = () => {
    setShowSecondModal(true);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowSecondModal(false);
    setShowModal(false);
  };

  const handlesavesetting = () => {
    setShowSecondModal(false);
    setShowModal(false);

    const cookieSettings = {
      cook1Enabled,
      cook2Enabled,
      cook3Enabled,
      cook4Enabled,
    };

    localStorage.setItem("cookieSettings", JSON.stringify(cookieSettings));
  };

  const calculateEnabledTogglesCount = () => {
    return [cook3Enabled].filter(Boolean).length;
  };
  const handleClick = () => {
    setShowCookies(!showCookies);
    const enabledTogglesCount = calculateEnabledTogglesCount();
    console.log(`Allowed: ${enabledTogglesCount} of 4`);
  };

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };

  const handleCook3Toggle = () => {
    console.log("toggle");
    setCook3Enabled(!cook3Enabled);
  };

  //cookie Accept
  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setShowSecondModal(false);
    setShowModal(false);
    setCook3Enabled(true);
    const cookieSettings = {
      cook3Enabled: true,
    };

    // Save cookie settings to local storage
    localStorage.setItem("cookieSettings", JSON.stringify(cookieSettings));
  };
  //with out cookie Accept
  const withoutacceptCookies = () => {
    posthog.opt_in_capturing();
    setShowSecondModal(false);
    setShowModal(false);

    // const cookieSettings = {
    //   cook1Enabled: true,
    // };

    // // Save cookie settings to local storage
    // localStorage.setItem("cookieSettings", JSON.stringify(cookieSettings));
  };

  const declineCookies = () => {
    setShowSecondModal(false);
    setShowModal(false);
    localStorage.clear();
    posthog.opt_out_capturing();
    setShowBanner(false);
    setCook1Enabled(false);
    setCook2Enabled(false);
    setCook3Enabled(false);
    setCook4Enabled(false);
  };

  return (
    <footer className="footer_head">
      <div className="container-fluid">
        <div className="row">
          <div className="column_1 col-lg-3 col-md-5 col-sm-5 p-0 mb-4 mt-2">
            <div className="contact_head">
              <h4>CONTACT</h4>
              <p>
                {" "}
                Welcome to Barley's Food Factory, an American Fun dining
                restaurant that combines gourmet food with hand-crafted
                microbreweries beers.
              </p>
              <ul className="contact_list p-0">
                <li>
                  <img
                    style={{ whiteSpace: "nowrap" }}
                    className="place_pink"
                    src={PlacePink}
                    alt={data.address}
                  />{" "}
                  &nbsp; {data.address}
                </li>
                <a href={phoneno}>
                  <li>
                    <img className="phone_pink" src={PhonePink} alt="Phone" />{" "}
                    &nbsp; +{data.phone_no}
                  </li>
                </a>
                <a href={emailcall}>
                  <li>
                    <img className="email_pink" src={EmailPink} alt="Email" />{" "}
                    &nbsp; {data.email_id}
                  </li>
                </a>
              </ul>
              <br />
              <div className="social_icons_footer">
                <ul>
                  <li>
                    <a href={data.facebook_link} target="_blank">
                      <img src={MetaWhite} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href={data.twitter_link} target="_blank">
                      <img src={Twitter} alt="twitter" />
                    </a>
                  </li>
                  <li>
                    <a href={data.instragram_link} target="_blank">
                      <img src={Insta} alt="instagram" />
                    </a>
                  </li>
                  <li>
                    <a href={data.linkedin_link} target="_blank">
                      <img src={LinkedIn} alt="linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href={data.snapchat_link} target="_blank">
                      <img src={Snapchat} alt="Snapchat" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="column_2 col-lg-3 col-md-5 p-0 d-none d-md-block">
            <div className=" working">
              <h4>WORKING</h4>
              <table>
                <tbody>
                  {week_datesnew.map((data, index) => (
                    <tr key={index}>
                      <td>{data.week_name}</td>
                      <td>
                        {data.open_close_status == 0 ? (
                          <>
                            <span>
                              {data.start_time} - {data.end_time}
                            </span>
                          </>
                        ) : (
                          data.data_show
                        )}
                      </td>
                    </tr>
                  ))}
                  {/* {daysOfWeek.map((day, index) => (
                    <tr key={index}>
                      <td>{day}</td>
                      <td>
                        {week_dates &&
                        week_dates[day] &&
                        week_dates[day].length > 0 &&
                        week_dates[day][0].data_show === "open"
                          ? week_dates[day].map((data_daa, indexddd) => {
                              return (
                                data_daa.data_show === "open" && (
                                  <>
                                    <span key={indexddd}>
                                      {indexddd !== 0 && ", "}
                                      {data_daa.combined_data}
                                    </span>
                                  </>
                                )
                              );
                            })
                          : "Closed"}
                      </td>
                    </tr>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>

          <div className="link_bg column_3 col-lg-2 col-md-3 col-sm-4 col-5 p-0 mt-2">
            <div className="footer_links">
              <h4>LINKS</h4>
              <div>
                <ul>
                  <li>
                    <a href={imagelink} rel="noreferrer" target="_blank">
                      MENU
                    </a>
                  </li>
                  <li style={{ whiteSpace: "nowrap" }}>
                    <a target="_blank" rel="noreferrer" href={tableorder_url}>
                      TABLE ORDER
                    </a>
                  </li>
                  <li style={{ whiteSpace: "nowrap" }}>
                    <Link onClick={() => handleLinkClick("/booking")}>
                      BOOK A TABLE
                    </Link>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href={delivery_url}>
                      DELIVERY
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href={takeaway_url}>
                      TAKEAWAY
                    </a>
                  </li>
                  <li>
                    <Link onClick={() => handleLinkClick("/careers")}>
                      CAREERS
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => handleLinkClick("/team")}>TEAM</Link>
                  </li>
                  <li
                    data-bs-toggle="modal"
                    data-bs-target="#termsModal"
                    style={{ color: "#deaa3a", cursor: "pointer" }}
                  >
                    TERMS & CONDITIONS
                  </li>
                  <li
                    onClick={handlePrivacy}
                    style={{ color: "#deaa3a", cursor: "pointer" }}
                  >
                    PRIVACY POLICY
                  </li>
                  <li
                    onClick={handleManage}
                    style={{ color: "#deaa3a", cursor: "pointer" }}
                  >
                    MANAGE COOKIES
                  </li>
                  {/* <li>
                    <div className="TeamFoottr">
                      {" "}
                      <button onClick={handleLinkClick}>Manage</button>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="instagram column_4 col-lg-2 col-sm-4 col-5">
            <div className="instagram_wrapper">
              {/* <h4>Instagram</h4> */}
              <div className="insta_img_wrapper">
                <a
                  href="https://www.instagram.com/barleysfoodfactory/"
                  target="_blanck"
                >
                  <img src={InstaImgBg} alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="xl" show={showPrivacy} onHide={handlePrivacyClose}>
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable m-0">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Privacy Policy
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handlePrivacyClose}
              ></button>
            </div>
            <div className="modal-body">
              <PrivacyPolicy />
            </div>
          </div>
        </div>
      </Modal>
      <div
        className="modal fade"
        id="termsModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Terms and Conditions of Use
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <TermsOfUse />
            </div>
            <div className="modal-footer" hidden>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="HomeeModel custom-modal"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>We value your Privacy</Modal.Title>
          <div className="d-flex  openClssBtn">
            <Button variant="primary openBttn" onClick={acceptCookies}>
              Accept
            </Button>
            <Button variant="secondary mngeBttn" onClick={handleManage}>
              Manage
            </Button>
          </div>
        </Modal.Header>
        <div className="beforrrIcn">
          {" "}
          <Modal.Body>
            <p>
              We and our trusted partners use cookies to improve your browsing
              experience, enhance site security, and tailor ads to your
              interests across other sites. See our{" "}
              <span onClick={handlePrivacy} style={{ cursor: "pointer" }}>
                Privacy and Cookies Statement for details.
              </span>
            </p>
          </Modal.Body>
        </div>

        <Modal.Footer>
          <div className="ConTAccpt">
            <Button variant="secondary" onClick={withoutacceptCookies}>
              Continue Without Accepting
              <div className="RightArrww">
                {" "}
                <img className="" src={crossIcn} alt="img"></img>
              </div>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        className="HomeeModel2"
        centered
        show={showSecondModal}
        onHide={handleClose}
        backdrop="static"
      >
        <div className="container">
          <Modal.Header>
            <Modal.Title>Manage Your Privacy Settings</Modal.Title>
            <div className="d-flex justify-content-between align-items-center openClssBtn">
              <p
                onClick={handlePrivacy}
                style={{ color: "#deaa3a", cursor: "pointer" }}
              >
                Privacy Policy
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <p>
              We want to be transparent about the data we and our partners
              collect and how we use it, so you can best exercise control over
              your personal data. For more information, please see our
              <span
                onClick={handlePrivacy}
                style={{ color: "#deaa3a", cursor: "pointer" }}
              >
                Privacy Policy
              </span>
            </p>
            <p>
              We use this information to improve the performance and experience
              of our site visitors. This includes improving search results,
              showing more relevant content and promotional materials, better
              communication, and improved site performance
            </p>
            <div className="cook">
              <div className="cookieesss">
                <button className="cookbbuttn" onClick={handleClick}>
                  <img className=" drppIcnn" src={imgDrp} alt="imgDrp"></img>
                  Cookies
                </button>

                <p>
                  <p>{calculateEnabledTogglesCount()} of 1 Allowed</p>
                </p>
              </div>
              {showCookies && (
                <div className="cookboxx">
                  {/* <div className="cookieCont">
                    <div className="cook1">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion}
                      >
                        {isOpen ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Strictly Necessary Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>
                        <input
                          type="checkbox"
                          id="switch1"
                          checked={cook1Enabled}
                          onChange={handleCook1Toggle}
                        />
                        <label htmlFor="switch1">Toggle</label>
                      </div>
                    </div>
                  </div>

                  {isOpen && (
                    <div className="cook1-content">
                      <p>
                        We don't currently use essential cookies on our website.
                        These are typically cookies strictly necessary for basic
                        website functionality, such as remembering your login
                        information or shopping cart contents. If we ever
                        introduce essential cookies in the future, you will be
                        informed and they may not be manageable through the
                        Cookie Consent Tool as they are crucial for the
                        website's operation.
                      </p>
                    </div>
                  )} */}
                  {/* <div className="cookieCont">
                    <div className="cook2">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion2}
                      >
                        {isOpen2 ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Functional Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>
                        <input
                          type="checkbox"
                          id="switch2"
                          checked={cook2Enabled}
                          onChange={handleCook2Toggle}
                        />
                        <label htmlFor="switch2">Toggle</label>
                      </div>
                    </div>
                  </div>

                  {isOpen2 && (
                    <div className="cook1-content">
                      <p>This is the content of cook1 when enabled.</p>
                    </div>
                  )} */}
                  <div className="cookieCont">
                    <div className="cook3">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion3}
                      >
                        {isOpen3 ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Analytics Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>

                        <input
                          type="checkbox"
                          id="switch3"
                          checked={cook3Enabled}
                          onChange={handleCook3Toggle}
                        />
                        <label htmlFor="switch3">Toggle</label>
                      </div>
                    </div>
                  </div>

                  {isOpen3 && (
                    <div className="cook1-content">
                      <p>
                        We use these cookies to help us understand how the
                        website is being used and how we can improve your
                        experience of it. These cookies can provide us with
                        information to help us understand which parts of the
                        website interest our visitors and if they experience any
                        errors. We use these cookies to test different designs
                        and features for our websites and we also use them to
                        help us monitor how visitors reach the website.
                      </p>
                    </div>
                  )}
                  {/* <div className="cookieCont">
                    <div className="cook4">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion4}
                      >
                        {cook4Enabled ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Marketing Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      {" "}
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>

                        <input
                          type="checkbox"
                          id="switch4"
                          checked={cook4Enabled}
                          onChange={handleCook4Toggle}
                        />

                        <label htmlFor="switch4">Toggle</label>
                      </div>
                    </div>
                  </div>

                  {isOpen4 && (
                    <div className="cook1-content">
                      <p>This is th when enabled.</p>
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </Modal.Body>
          <div className="fottrBtnModl">
            <Modal.Footer>
              <div className="leftFottrBtn">
                <div className="accpptBtn">
                  <button onClick={acceptCookies}> Accept All</button>
                </div>

                <div className="RjectBtn">
                  {" "}
                  <button onClick={declineCookies}> Reject All</button>
                </div>
              </div>

              <Button
                variant="secondary sveSetting"
                onClick={handlesavesetting}
              >
                Save Settings
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </footer>
  );
}

export default Footer;
