import React from "react";
import "../Css/TeamPageCss.css";
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import {
  get_team,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";

function TeamHeader() {
  const [namess, setData1] = useState([]);
  const [imagelink, setimagelink] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const handleFetchData = async () => {
    setShowLoader(true);
    var form_data = new FormData();
    await server_post_data(get_team, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setimagelink(Response.data.message.team_link);
          setData1(Response.data.message.team_data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  return (
    <>
      <div className={showLoader ? "loading" : ""}></div>
      <Header />
      <section className="team_section">
        <div className="team_wrapper">
          <div className="container">
            <h2>Our Scientific Leadership Team</h2>
          </div>
          <div className="team_details">
            <div className="container">
              <div className="row_1 row" id="footer_icons_hide">
                {namess.map((membr, index) => (
                  <div
                    className="team_member col-lg-4 col-md-4 col-9"
                    key={index}
                  >
                    <div className="member_img col-md-10">
                      <img
                        src={imagelink + membr.team_image}
                        alt={membr.team_name}
                      ></img>
                    </div>
                    <div
                      className="member_detail member_detail_1"
                      id="footer_icons_hide"
                    >
                      <div className="detail_text">
                        <h4>{membr.team_name}</h4>
                        <p>{membr.team_position}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TeamHeader;
