import React, { useState, useEffect } from "react";
import logo from "../Assets/logo/logo.png";
import BookingPage from "./BookingPage";
import Meta from "../Assets/images/meta.svg";
import Twitter from "../Assets/images/twitter.svg";
import Insta from "../Assets/images/insta.svg";
import LinkedIn from "../Assets/images/linkedIn.svg";
import { Link } from "react-router-dom";
import {
  footer_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
import "../SrollIcons";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
export default function BookingHeader() {
  const [data, setData] = useState([]);
  const [imagelink, setimagelink] = useState([]);
  const [takeaway_url, settakeaway_url] = useState([]);
  const [tableorder_url, settableorder_url] = useState([]);
  const [delivery_url, setdelivery_url] = useState([]);

  const handleFetchData = async () => {
    var form_data = new FormData();
    await server_post_data(footer_data, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          if (Response.data.message.footer_data.length > 0) {
            setData(Response.data.message.footer_data[0]);
          }

          setimagelink(Response.data.message.menu_file);
          settakeaway_url(Response.data.message.takeaway_url);
          settableorder_url(Response.data.message.tableorder_url);
          setdelivery_url(Response.data.message.delivery_url);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <header className="booking_header">
      <div className="header_container">
        <div className="header_bg home_page_header home_page_header2">
          <div className="nav_wrapper nav_wrapper2">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand small_logo d-lg-none" href="/">
                <img
                  src={logo}
                  alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                  loading="lazy"
                  fetchPriority="high"
                />
              </a>
              <button
                className="navbar-toggler nav_mbl_btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse nav_right"
                id="navbarNavAltMarkup"
              >
                <ul className="navbar-nav">
                  <ul className="navbar-nav newNav">
                    <li className="nav-item">
                      <Link
                        className="nav-link nowrap white_hover text_white"
                        onClick={() => handleLinkClick("/")}
                      >
                        Events
                      </Link>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link nowrap white_hover text_white"
                        target="_blank"
                        rel="noreferrer"
                        href={tableorder_url}
                      >
                        Table order
                      </a>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link nowrap white_hover text_white"
                        onClick={() => handleLinkClick("/booking")}
                      >
                        Book a table
                      </Link>
                    </li>
                  </ul>

                  <a
                    className="navbar-brand logoHideInTab logo d-none d-lg-block"
                    href="/"
                  >
                    <img
                      src={logo}
                      alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                    />
                  </a>

                  <ul className="navbar-nav newNav">
                    <li className="nav-item">
                      <a
                        className="nav-link white_hover text_white"
                        target="_blank"
                        rel="noreferrer"
                        href={delivery_url}
                      >
                        Delivery
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link white_hover text_white"
                        target="_blank"
                        rel="noreferrer"
                        href={takeaway_url}
                      >
                        Takeaway
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link white_hover text_white"
                        href={imagelink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Menu
                      </a>
                    </li>
                  </ul>
                </ul>
              </div>
            </nav>
          </div>

          <div className="welcome_wrapper position-relative d-none">
            <div className="social-icons" id="social_icons_scrolling">
              <ul>
                <li>
                  <a href={data.facebook_link}>
                    <img src={Meta} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a href={data.twitter_link}>
                    <img src={Twitter} alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a href={data.instragram_link}>
                    <img src={Insta} alt="instagram" />
                  </a>
                </li>
                <li>
                  <a href={data.linkedin_link}>
                    <img src={LinkedIn} alt="LinkedIn" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="booking_form_container">
            <BookingPage />
          </div>
        </div>
      </div>
    </header>
  );
}
