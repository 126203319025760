import React from "react";
import "../Css/PrivacyPolicy.css";
import { Table } from "react-bootstrap";

function PrivacyPolicy() {
  const Tabledata = [
    {
      data: "Name, Phone Number, Email (Reservations)",
      legalBasis: "Contract Fulfillment (GDPR Article 6(1)(b))",
      explanation:
        "This information is essential to confirm and complete the reservation service for the user",
    },
    {
      data: "Name, Contact Details, Resume/CV (Careers)",
      legalBasis: "Pre-contractual Measures (GDPR Article 6(1)(b))",
      explanation:
        "These details are necessary to assess job applications and progress through the recruitment process.",
    },
    {
      data: "Website Cookies",
      legalBasis:
        "Legitimate Interests (GDPR Article 6(1)(f)),Consent (GDPR Article 6(1)(a))",
      explanation:
        "Essential cookies that are strictly necessary for website functionality (e.g., session management) can be justified under legitimate interests.Analytical and Marketing cookies are collected only after gaining user’s consent.",
    },
  ];

  return (
    <div className="privacy_policy">
      <div className="container privacy_policy_wrapper">
        <div className="privacy_policy_container">
          <div>
            <strong>
              <h6 style={{ marginTop: "1rem" }}>1. Introduction</h6>
            </strong>
            <p>
              We know that sharing your personal information with us is based on
              trust. We take this seriously and are committed to ensuring that
              we respect your privacy when you visit our website or use our
              services. Please review this Statement carefully to learn about
              our privacy practices
              <br />
            </p>
          </div>
          <div>
            <strong>
              <h6>2. Topics:</h6>
            </strong>
            <ul>
              <li>
                <p>&nbsp;What data do we collect?</p>
              </li>
              <li>
                <p>&nbsp;How do we collect your data?</p>
              </li>
              <li>
                <p>&nbsp;How will we use your data?</p>
              </li>
              <li>
                <p>&nbsp;How will we store your data?</p>
              </li>
              <li>
                <p>&nbsp;What are your data protection rights?s</p>
              </li>
              <li>
                <p>&nbsp;What are cookies?</p>
              </li>
              <li>
                <p>&nbsp;How do we use cookies?</p>
              </li>
              <li>
                <p>&nbsp;Privacy policies of other websites</p>
              </li>
              <li>
                <p>&nbsp;Changes to our privacy policy</p>
              </li>
              <li>
                <p>&nbsp;How to manage your cookies</p>
              </li>
              <li>
                <p>&nbsp;How to contact us</p>
              </li>
              <li>
                <p>&nbsp;How to contact the appropriate authorities</p>
              </li>
            </ul>
          </div>
          <div>
            <strong>
              <h6>3.What data do we collect?</h6>
            </strong>
            <p>
              Barley’s Food Factory collects certain information from you when
              you decide to use or access our services. This includes::
            </p>
            <ul>
              <li>
                <p>Name</p>
              </li>
              <li>
                <p>Phone Number</p>
              </li>
              <li>
                <p>Email address </p>
              </li>
              <li>
                <p>Resume or Curriculum Vitae [For Career opportunities]</p>
              </li>
              <p>Legal basis for collection of the above mentioned data:</p>
            </ul>
          </div>
          <div class="containe tblData">
            <Table className="table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Legal Basis</th>
                  <th>Explanations</th>
                </tr>
              </thead>
              <tbody>
                {Tabledata.map((item, index) => (
                  <tr key={index}>
                    <td>{item.data}</td>
                    <td>{item.legalBasis}</td>
                    <td>{item.explanation}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <p>
              Note: The website currently only gathers analytical and functional
              cookies, with no collection of essential cookies at this time. Any
              updates or modifications to this will be communicated to you via
              our website.
            </p>
          </div>

          <div>
            <strong>
              <h6>4. How do we store your data?</h6>
            </strong>
            <p>
              <strong style={{ color: "black" }}>4.1.</strong>&nbsp;Barley’s
              Food Factory securely stores your data at Amazon data centers
              present in Sweden while maintaining secure protocols and
              encryption techniques.
            </p>
            <p>
              <strong style={{ color: "black" }}>4.2.</strong>&nbsp; We will
              keep your personal data for 30 days. Once this time period has
              expired, we will delete your data by automated means.
            </p>
            <p>
              <strong style={{ color: "black" }}>4.3.</strong>&nbsp; When we
              mention "automated means," we're referring to using automated
              processes or technology to delete your data once the specified
              time period has passed. This could involve programming
              instructions or algorithms that are set up to automatically remove
              your data from our systems after the 30-day period without the
              need for manual intervention. These automated processes help
              ensure that your personal data is promptly and securely deleted
              according to our data retention policies.
            </p>
          </div>
          <div>
            <strong>
              <h6>5. What are your data protection rights?</h6>
            </strong>
            <p>
              We want to empower you with control over your personal data. Under
              GDPR, you have several rights you can exercise:
            </p>
            <ol>
              <li>
                1. Right to Access:
                <ul>
                  <li>
                    You can request a copy of the personal data we hold about
                    you. This helps you verify what data we have and ensure its
                    accuracy.
                  </li>
                  <li>
                    To request access, simply contact us using the information
                    provided in the "How to Contact Us" section. We may charge a
                    small fee for excessive or repetitive requests.
                  </li>
                </ul>
              </li>
              <li>
                2. Right to Rectification:
                <ul>
                  <li>
                    If you believe any of your personal data is inaccurate or
                    incomplete, you have the right to request corrections or
                    updates.
                  </li>
                  <li>
                    You can submit a request to rectify your data through our
                    contact information. We will respond to your request within
                    one month.
                  </li>
                </ul>
              </li>
              <li>
                3. Right to Erasure (Right to be Forgotten):
                <ul>
                  <li>
                    Under certain circumstances, you can request us to erase
                    your personal data. This right applies if:
                    <ul>
                      <li>
                        Your data is no longer necessary for the purposes we
                        collected it for.
                      </li>
                      <li>
                        You withdraw your consent for data processing (if the
                        processing relied on consent).
                      </li>
                      <li>
                        You object to the processing of your data and we don't
                        have overriding legitimate grounds to continue
                        processing it.
                      </li>
                      <li>Your data has been unlawfully processed.</li>
                      <li>
                        Your data needs to be erased to comply with a legal
                        obligation.
                      </li>
                    </ul>
                  </li>
                  <li>
                    To submit an erasure request, please contact us using the
                    provided information. We will respond within one month to
                    confirm whether your request has been granted.
                  </li>
                </ul>
              </li>
              <li>
                4. Right to Restriction of Processing:
                <ul>
                  <li>
                    You have the right to request that we restrict the
                    processing of your personal data in certain situations. This
                    means we can store your data but not use it further.
                  </li>
                  <li>
                    Restriction applies if:
                    <ul>
                      <li>
                        You contest the accuracy of your data, and we need time
                        to verify its accuracy.
                      </li>
                      <li>
                        The processing is unlawful, but you don't want us to
                        erase your data.
                      </li>
                      <li>
                        We no longer need your data, but you require it for
                        legal purposes.
                      </li>
                      <li>
                        You have objected to the processing, and we are
                        considering the validity of your objection.
                      </li>
                    </ul>
                  </li>
                  <li>
                    To request restriction of processing, contact us through our
                    provided channels. We will assess your request and respond
                    within one month.
                  </li>
                </ul>
              </li>
              <li>
                5. Right to Object to Processing:
                <ul>
                  <li>
                    You have the right to object to the processing of your
                    personal data, particularly when it's based on our
                    legitimate interests. We will stop processing your data
                    unless we can demonstrate compelling legitimate grounds that
                    override your interests, rights, and freedoms, or for the
                    establishment, exercise, or defense of legal claims.
                  </li>
                  <li>
                    To object to processing, contact us using the methods
                    provided. We will respond within one month to inform you of
                    the outcome.
                  </li>
                </ul>
              </li>
              <li>
                6. Right to Data Portability:
                <ul>
                  <li>
                    This right allows you to request that we transfer the
                    personal data you provided to us to another organization, in
                    a structured, commonly used, and machine-readable format.{" "}
                    This right applies to data you have given us consent to
                    process, or data necessary for the performance of a contract
                    with you.
                  </li>
                  <li>
                    To request data portability, contact us using the provided
                    information. We will assess your request and respond within
                    one month.
                  </li>
                </ul>
              </li>
              <li>
                Exercising Your Rights:
                <ul>
                  <li>
                    We aim to respond to all data protection requests within one
                    month. This timeframe may be extended for complex requests
                    or if we receive a high volume of requests. We will inform
                    you if an extension is necessary.
                  </li>
                  <li>
                    If you would like to exercise any of these rights, please
                    contact us at our email: Call us at: +4631515748 Or write to
                    us: boka@barleys.se
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div>
            <strong>
              <h6>6. What are cookies?</h6>
            </strong>
            <p>
              Cookies are text files placed on your computer to collect standard
              Internet log information and visitor behavior information. When
              you visit our websites, we may collect information from you
              automatically through cookies or similar technology.
            </p>
          </div>
          <div>
            <strong>
              <h6>7.How do we use cookies?</h6>
            </strong>
            <p>
              Our Site uses cookies in a range of ways to improve your
              experience on our website, including:
            </p>
            <ul>
              <li>Understanding how you use our website</li>
              <li>
                These cookies are used to throttle the request rate and to
                distinguishes unique users by assigning a randomly generated
                number as a client identifier. It is included in each page
                request in a site and used to calculate visitor, session and
                campaign data for the sites analytics reports.{" "}
              </li>
            </ul>
          </div>
          <div>
            <strong>
              <h6>8. What types of cookies do we use?</h6>
            </strong>
            <p>
              <strong style={{ color: "black" }}>8.1.</strong>
              &nbsp;There are a number of different types of cookies, however,
              our website uses:
            </p>
            <br />
            <div class="containe tblData">
              <Table className="table">
                <thead>
                  <tr>
                    <th>Cookie:</th>
                    <th>Details: </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Essential</td>
                    <td>
                      We don't currently use essential cookies on our website.
                      These are typically cookies strictly necessary for basic
                      website functionality, such as remembering your login
                      information or shopping cart contents. If we ever
                      introduce essential cookies in the future, you will be
                      informed and they may not be manageable through the Cookie
                      Consent Tool as they are crucial for the website's
                      operation.
                    </td>
                  </tr>
                  <tr>
                    <td>Analytics and customisation</td>
                    <td>
                      We use these cookies to help us understand how the website
                      is being used and how we can improve your experience of
                      it. These cookies can provide us with information to help
                      us understand which parts of the website interest our
                      visitors and if they experience any errors. We use these
                      cookies to test different designs and features for our
                      websites and we also use them to help us monitor how
                      visitors reach the website. You can turn off analytics and
                      customisation through the Cookie Consent Tool.
                    </td>
                  </tr>

                  <tr>
                    <td>Functional Cookies</td>
                    <td>
                      These cookies are used to enable and manage the Google
                      Identity Platform's authentication services, allowing
                      users to sign in to websites using their Google accounts.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <p>
              Note: The website currently only gathers analytical and functional
              cookies, with no collection of essential cookies at this time. Any
              updates or modifications to this will be communicated to you via
              our website.
            </p>
            <p>Cookies used by our site:</p>
            <div class="containe tblData">
              <Table className="table">
                <thead>
                  <tr className="leftMarginTbl">
                    <th>Cookie:</th>
                    <th>Name & Expiration time </th>
                    <th className="leftMarginTbl">
                      First party or third party{" "}
                    </th>
                    <th>Purpose </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Google Analytics </td>
                    <td>_ga (2 years)</td>
                    <td>First party</td>
                    <td>
                      These cookies are used to throttle the request rate and to
                      distinguishes unique users by assigning a randomly
                      generated number as a client identifier. It is included in
                      each page request in a site and used to calculate visitor,
                      session and campaign data for the sites analytics reports.
                      For more information, please visit
                      www.google.com/policies/privacy/partners/ If you wish to
                      disable Google Analytics on your browser please visit
                      http://tools.google.com/dlpage/gaoptout.
                    </td>
                  </tr>
                  <tr>
                    <td>Google SSO</td>
                    <td>G_ENABLED_IDPS(Session)</td>
                    <td>First Party</td>
                    <td>
                      This cookie is used to enable and manage the Google
                      Identity Platform's authentication services, allowing
                      users to sign in to websites using their Google accounts.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div>
            <strong>
              <h6>9. How to manage cookies?</h6>
            </strong>
            <p>
              To give you control, we provide you with a Cookie Consent Tool,
              which allows you to review the first party and third-party cookies
              placed through our website and adjust your cookie settings,
              including whether to accept or decline such cookies.
            </p>
            <br />
            <p>
              You can access the Cookie Consent Tool by clicking on **Link to be
              inserted here** or at any time through the “Cookie Consent” link
              at the bottom of every page or by modifying your preferences in
              our cookie banner. In addition, you can manage cookies through the
              settings of your internet browser. You can have the browser notify
              you when you receive a new cookie, delete individual cookies or
              delete all cookies.
            </p>
          </div>
          <div>
            <strong>
              <h6>10. Privacy policies of other websites:</h6>
            </strong>
            <p>
              The website “website.barleys.se” contains links to other websites.
              Our privacy policy applies only to our website, so if you click on
              a link to another website, you should read their privacy policy.
            </p>
            <br />
          </div>
          <div>
            <strong>
              <h6>11. Careers:</h6>
            </strong>
            <strong>
              <h6>Application Consent:</h6>
            </strong>
            <p>
              The data controller for the processing of your personal data is
              Barley's Food Factory , with a registered office at Kvilletorget
              20, 417 04 Göteborg, Sweden. Barley's Food Factory collects your
              personal data for the purposes of managing Barley's Food Factory’s
              recruitment-related activities as well as for organizational
              planning purposes globally. Consequently, Barley's Food Factory
              may use your personal data in relation to the evaluation and
              selection of applicants including, for example, setting up and
              conducting interviews and tests, evaluating and assessing the
              results thereto, and as is otherwise needed in the recruitment
              processes including the final recruitment. The legal basis for the
              processing of your personal data is the application of
              pre-contractual measures at the request of the interested party as
              established by art. 6.1b) of GDPR.
            </p>
            <br />
            <p>
              If you join Barley's Food Factory, the personal data collected
              will become part of your employment record. If your application is
              unsuccessful, your personal data will be added to our candidate
              database and can be retained and used to consider you for
              opportunities at Barley's Food Factory and its affiliates other
              than the one(s) for which you apply. If you do not wish for us to
              do this, please contact us at{" "}
              <a href=" boka@barleys.se"> boka@barleys.se</a>
            </p>
            <br />
            <p>
              In all cases, Barley's Food Factory will retain your personal data
              for the period necessary to fulfill the purposes outlined in this
              Application consent Policy unless a longer retention period is
              required or permitted by law. The criteria used to determine our
              retention periods are: (i) the duration of the application
              process; (ii) the period of time that we have an ongoing
              relationship with you; (iii) as required by a legal obligation to
              which we are subject; and (iv) as advisable in light of our legal
              position (such as in regard to applicable statutes of limitations,
              litigation, or regulatory investigations).
            </p>
            <br />
            <p>
              Barley's Food Factory does not disclose your personal data to
              unauthorized third parties. In order to contact your referees or
              to detect, prevent, or otherwise address fraud, security, or
              technical issues, or to protect against harm to the rights,
              property, or safety of Barley's Food Factory, our users,
              applicants, candidates, employees, or the public or as otherwise
              required by law. We have put in place adequate safeguards with
              respect to the protection of your privacy, rights, and freedoms,
              and the exercise of your rights.
            </p>
            <br />
            <p>
              If you would like to request to review, correct, update, suppress,
              restrict, or delete your personal information, object to the
              processing of your personal information, or if you would like to
              request to receive an electronic copy of your personal (to the
              extent this right to data portability is provided to you by
              applicable law), you may contact us{" "}
              <a href=" boka@barleys.se"> boka@barleys.se.</a>
            </p>
            <br />
            <p>
              Should you consider that Barley's Food Factory has not processed
              your personal data in accordance with applicable law, you can
              lodge a complaint with the relevant supervisory authority.
            </p>
          </div>
          <div>
            <strong>
              <h6>12. Changes to our privacy policy</h6>
            </strong>
            <p>
              We keep our privacy policy under regular review and place any
              updates on this web page. This privacy policy was last updated on
              13 April 2024.
            </p>
            <br />
            <strong>
              <h6>How to contact us ?</h6>
            </strong>
            <p>
              If you have any questions about our privacy policy, the data we
              hold on you, or you would like to exercise one of your data
              protection rights, please do not hesitate to contact us.
              <br /> Email us at: <a href="boka@barleys.se">
                {" "}
                boka@barleys.se
              </a>{" "}
              <br /> Call us: <a href="+4631515748"> +4631515748</a> <br />
              Or write to us at: Kvilletorget 20, 417 04 Göteborg, Sweden
            </p>
          </div>
          <div>
            <strong>
              <h6>13. How to contact the appropriate authority ?</h6>
            </strong>
            <p>
              Should you wish to report a complaint or if you feel that Barley’s
              Food Factory has not addressed your concern in a satisfactory
              manner, you may contact the Information Commissioner’s Office.
            </p>
            <br />
            <p>
              Phone number: <br />
              <a href="+46 (0)8 657 61 00"> +46 (0)8 657 61 00</a>
            </p>
            <p>
              E-mail: <a href="imy@imy.se">imy@imy.se</a>
            </p>
            <p>
              Postal address:
              <br /> Integritetsskyddsmyndigheten, Box 8114, 104 20 Stockholm,
              Sweden
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
