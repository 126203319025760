import $ from "jquery";
const validateEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};

const validateName = (name) => {
  const nameRegex = /^[A-Za-z\s]+$/;
  return nameRegex.test(name);
};

const validateMobile = (mobile) => {
  const mobileRegex = /^[0-9]{8,12}$/;
  return mobileRegex.test(mobile);
};

const validateZip = (zip) => {
  const mobileRegex = /^\d{5}(?:-\d{4})?$/;
  return mobileRegex.test(zip);
};

const check_vaild_save = (class_name) => {
  var isValid = true;

  $("#" + class_name + " .trio_mendate").each(function () {
    let trioEmailElement = $(this).attr("class").includes("trio_email");
    let trioMobnolElement = $(this).attr("class").includes("trio_no");
    let triopasswordlElement = $(this).attr("class").includes("trio_password");
    let triocountlElement = $(this).attr("class").includes("trio_count");
    let trioNamelElement = $(this).attr("class").includes("trio_name");
    let trioZiplElement = $(this).attr("class").includes("trio_zip");
    $(this).css({
      border: "",
      background: "",
    });
    $(this).nextAll(".condition_error:first").hide();
    if ($(this).val() === "") {
      isValid = false;
      $(this).css({
        border: "1px solid red",
        width: "50px !important",
      });
      $(this)
        .nextAll(".condition_error:first")
        .html("Please Fill The Mandatory Information")
        .show();
    } else {
      if (trioEmailElement) {
        let emailid = $(this).val();
        if (!validateEmail(emailid)) {
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          isValid = false;
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Fill Valid Email Id")
            .show();
        }
      }
      if (trioZiplElement) {
        let zipcode = $(this).val();
        if (!validateZip(zipcode)) {
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          isValid = false;
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Enter Valid Zip Code")
            .show();
        }
      }
      if (trioNamelElement) {
        let name = $(this).val();
        if (!validateName(name)) {
          isValid = false;
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Fill Valid Name")
            .show();
        }
      }
      if (trioMobnolElement) {
        let mob_no = $(this).val();
        if (!validateMobile(mob_no)) {
          isValid = false;
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Enter Valid Mobile No")
            .show();
        }
      }
      if (triopasswordlElement) {
        let pass_no = $(this).val();

        if (pass_no.length < Number(8)) {
          $(this).css({
            border: "1px solid red",
            width: "50px !important",
          });
          $(this)
            .nextAll(".condition_error:first")
            .html("Please Enter Valid Password")
            .show();
        }
      }
      if (triocountlElement) {
        let count_no = $(this).val();

        if (Number(count_no) === 0) {
          alert("Please Add Items");
        }
      }
    }
  });

  return isValid;
};

const combiled_form_data = (form_name, dynaicimage) => {
  const fd = new FormData(document.getElementById(form_name));
  for (const [key, value] of fd.entries()) {
    if (value.type && dynaicimage != null) {
      fd.append(key + "_new", dynaicimage[key]);
    }
  }
  return fd;
};

const empty_form = (class_name) => {
  if (class_name !== "form_data_profile") {
    $("#" + class_name + " .trio_mendate").each(function () {
      $(this).val("");
      $(this).css({
        border: "",
        background: "",
      });
    });
  }
};

const getRandomSixLetterString = () => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  let randomString = "";
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    randomString += alphabet[randomIndex];
  }
  return randomString;
};

const handleLinkClick = (link) => {
  // Reload the page when the link is clicked

  window.location.href = link;
};

const formatTimeintotwodigit = (timeString) => {
  try {
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = timeParts.length > 2 ? parseInt(timeParts[2], 10) : 0; // Check if seconds exist

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    const hours_one = String(date.getHours()).padStart(2, "0");
    const minutes_one = String(date.getMinutes()).padStart(2, "0");
    return `${hours_one}:${minutes_one}`;
  } catch (err) {
    return null;
  }
};

export {
  empty_form,
  check_vaild_save,
  combiled_form_data,
  getRandomSixLetterString,
  handleLinkClick,
  formatTimeintotwodigit,
};
