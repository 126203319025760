import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {
  get_blog_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
function Blog() {
  const [blogs, setblogs] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const handleFetchData = async () => {
    setShowLoader(true);
    var form_data = new FormData();

    await server_post_data(get_blog_data, form_data)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setblogs(Response.data.message.blog_list);
          setSEOloop(Response.data.message.seo_loop);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;

    if (SEOloop) {
      const matchedItem = SEOloop.find(
        (data) => data_seo_link === data.call_function_name
      );

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };
  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="blogs">
      <div className={showLoader ? "loading" : ""}></div>
      <Header />
      <div className="blogsCOntainer container-lg">
        <h2 className="blogPostHeading">
          Explore Blogs About The Gourmet Burgers, Microbrews, and Culinary
          Adventures
        </h2>

        <section className="barleysBLog">
          <div className="barleysBLogContainer">
            <div className="barleysBLogWrapper">
              <div className="row mx-0 barleysBLogCardscontainer">
                {blogs.map((data, index) => (
                  <div
                    key={index}
                    className="col-lg-3 col-sm-4 mb-5"
                    // data-aos="fade-up"
                  >
                    <Link
                      onClick={() =>
                        handleLinkClick(
                          match_and_return_seo_link(data.primary_id)
                        )
                      }
                    >
                      <div className="barleysBLogcard">
                        <div className="barleysBLogcardHead">
                          <img
                            src={data.image_url}
                            alt="The Best Restaurant in Sweden, Barley's Food Factory, hand-crafted microbreweries, gourmet burger restaurants in Sweden, microbreweries, burger restaurant, Swedish cuisine"
                          />
                        </div>
                        <div className="barleysBLogcardbody">
                          <h5>{data.title_name}</h5>
                          <h6>{data.tag_line}</h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
