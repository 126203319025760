import React, { useState, useEffect } from "react";
import $ from "jquery";
import "../Css/BookingPageCss2.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import { Modal, Button } from "react-bootstrap";
import SuccfllIcon from "../Assets/images/sentDOne.png";

import "owl.carousel";
import "../SrollIcons";
import {
  server_post_data,
  get_all_timing_date_wise,
  create_table_reservation_website,
} from "../ServiceConnection/serviceconnection.js";
import { formatTimeintotwodigit } from "../CommonJquery/CommonJquery.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "react-international-phone/style.css";
import PrivacyPolicy from "./PrivacyPolicy.js";
function BookingPage({ dataProvider }) {
  let one_by_call = 0;
  const [data, setData] = useState([]);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedChild, setselectedChild] = useState(0);
  const [selectedpet, setselectedpet] = useState(0);
  const [phoneno, setPhoneno] = useState("4631515748"); //default phonenumber for testing
  const [selectedrtsd_idd, setSelectedrtsd_idd] = useState(null);
  const [phone, setPhone] = useState("");
  const [errorform, seterrorform] = useState({ error: false });
  const [errormsg, seterrormsg] = useState([]);
  const [isValid, setisValid] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalKitchen, setShowModalKitchen] = useState(false);
  const [showModalKitchenMsg, setShowModalKitchenMsg] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showPrivacy, setshowPrivacy] = useState(false);
  const [TimeData, setTimeData] = useState([]);
  const handlePrivacy = () => {
    setshowPrivacy(true);
  };

  const handlePrivacyClose = () => {
    setshowPrivacy(false);
  };

  const handleFetchData = async (date_for_book) => {
    if (one_by_call === 0) {
      one_by_call = 1;
      setShowLoader(true);
      setData(null);
      var form_data = new FormData();

      form_data.append("special_date", date_for_book);
      form_data.append("reservation_id", "0");
      await server_post_data(get_all_timing_date_wise, form_data)
        .then((Response) => {
          one_by_call = 0;
          setTimeData(Response.data.message.data_timedata);
          setShowModalKitchenMsg(Response.data.message.kichan_lose_msg);
          setShowLoader(false);
          if (Response.data.message.footer_data.length > 0) {
            setPhoneno(Response.data.message.footer_data[0].phone_no);
          }
          let online_booking_status = 0;
          let start_stop_status = 0;
          if (Response.data.message.get_date_off_date.length > 0) {
            online_booking_status =
              Response.data.message.get_date_off_date[0].online_booking_status;
            start_stop_status =
              Response.data.message.get_date_off_date[0].start_stop_status;
          } else {
            if (Response.data.message.get_date_off_on_day.length > 0) {
              online_booking_status =
                Response.data.message.get_date_off_on_day[0]
                  .online_booking_status;
              start_stop_status =
                Response.data.message.get_date_off_on_day[0].start_stop_status;
            }
          }
          if (
            Response.data.message.data_timedatadetails.length > 0 &&
            online_booking_status === 0 &&
            start_stop_status === 0
          ) {
            setData(Response.data.message.data_timedatadetails);
            seterrorform({ error: true });
          } else {
            seterrormsg(Response.data.message.msg_for_no_show);
            seterrorform({ error: false });
          }
          setSelectedTime(null);
          setSelectedGuest(null); // Set the selected guest count
          setCurrentStep(2);
        })
        .catch((error) => {
          setShowLoader(false);
          one_by_call = 0;
        });
    }
  };

  useEffect(() => {
    handleFetchData(null);
  }, []);

  const sava_booking_data = async () => {
    setShowLoader(true);

    var fd_from = new FormData();

    fd_from.append("reservation_id", "0");
    fd_from.append("guest_mobile_no", phone.replace(/\+/g, ""));
    fd_from.append("book_date", selectedDate);
    fd_from.append("operational_time_detail_id", selectedrtsd_idd);
    fd_from.append("operational_booking_time", selectedTime);
    fd_from.append("booking_type", "2");
    fd_from.append("realtime", "0");
    if (selectedChild === "") {
      fd_from.append("no_of_child", 0);
    } else {
      fd_from.append("no_of_child", selectedChild);
    }
    if (selectedpet === "") {
      fd_from.append("no_of_pets", 0);
    } else {
      fd_from.append("no_of_pets", selectedpet);
    }
    fd_from.append("no_of_guest", selectedGuest);

    fd_from.append("total_tablebooking", "0");
    fd_from.append("dining_area_id", "0");
    fd_from.append("reservation_comment", $("#order_comment").val());
    fd_from.append("guest_name", $("#namecall").val());
    fd_from.append("guest_email", $("#emailcall").val());

    await server_post_data(create_table_reservation_website, fd_from)
      .then((Response) => {
        setShowLoader(false);
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          $("#namecall").val("");
          $("#emailcall").val("");
          setPhone("");
          $("#order_comment").val("");
          handleclickbackstep(0, "");
          setModalMessage(Response.data.message);
          handleShow();
        }
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };

  /////////////////////////////VALIDATIONS////////

  $("body").on("keypress keyup blur", ".only_num", function (event) {
    $(this).val(
      $(this)
        .val()
        .replace(/[^\d].+/, "")
    );
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const function_save = () => {
    var isValid = true;
    let emailid = document.querySelector(".trio_email").value;

    $(".trio_mendate").each(function () {
      let name_query = $(this).attr("id");
      if ($(this).val() === "") {
        isValid = false;
        $("#" + name_query).css({ border: "1px solid red !important" });
      } else {
        $("#" + name_query).css({ border: "" });
      }
    });
    if (!validateEmail(emailid)) {
      $(".trio_email").css({
        border: "1px solid red !important",
      });
      isValid = false;
    }
    const isValiddd = isPhoneValid(phone);
    setisValid(isValiddd);
    if (!isValiddd) {
      isValid = false;
    }

    if (isValid === true) {
      // Call your save booking data function
      if ($("#checkSurfaceEnvironment-1").prop("checked") === true) {
        //do something
        sava_booking_data();
      } else {
        alert(
          "Please read and agree to the terms and conditions before proceeding."
        );
      }
    }
  };

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handleclickstep = (step_click, data_for_update) => {
    if (step_click === 0) {
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedGuest(null); // Set the selected guest count
      setCurrentStep(1);
    } else if (step_click === 1) {
      let month = data_for_update.$M + 1;
      let day = data_for_update.$D;
      let year = data_for_update.$y;

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let full_date = year + "-" + month + "-" + day;

      setSelectedDate(full_date);

      handleFetchData(full_date);
    } else if (step_click === 2) {
      let make_data = data_for_update.split("~@~");
      setSelectedrtsd_idd(make_data[0]);
      setSelectedTime(make_data[1]);
      setSelectedGuest(null); // Set the selected guest count
      let click_by_popup = 0;
      TimeData.map((item) => {
        if (make_data[1] >= item.last_arrival_time) {
          click_by_popup = 1;
          setShowModalKitchen(true);
        }
      });
      if (click_by_popup === 0) {
        setCurrentStep(3);
      }
    } else if (step_click === 3) {
      setSelectedGuest(data_for_update); // Set the selected guest count
      setCurrentStep(4);
    } else if (step_click === 4) {
    }
  };

  const handleclickbackstep = (step_click, data_for_update) => {
    if (step_click === 0) {
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedGuest(null); // Set the selected guest count
      setCurrentStep(1);
    } else if (step_click === 1) {
      setSelectedTime(data_for_update);
      setSelectedGuest(null); // Set the selected guest count
      setCurrentStep(2);
    } else if (step_click === 2) {
      setSelectedGuest(data_for_update); // Set the selected guest count
      setCurrentStep(3);
    } else if (step_click === 3) {
      setSelectedGuest(data_for_update); // Set the selected guest count
      setCurrentStep(4);
    } else if (step_click === 4) {
    }
  };

  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const min = 1;
  const max = 999; // 1000 is not included

  const handleChangeForm = () => {
    let vaild_data = true;
    const guest_count = document.getElementById("guest_count").value;
    const child_count = document.getElementById("child_count").value;
    const pet_count = document.getElementById("pet_count").value;
    if (guest_count === "") {
      setError("Please Enter Number of Guest");
      $("#guest_count").css({ border: "1px solid red !important" });
      vaild_data = false;
    } else if (isNaN(guest_count)) {
      setError("Please Enter Number of Guest");
      vaild_data = false;
      $("#guest_count").css({ border: "1px solid red !important" });
    } else if (
      parseInt(guest_count) < min ||
      parseInt(guest_count) > max ||
      guest_count.length > 3
    ) {
      setError(`Please enter a number between ${min} and ${max}.`);
      $("#guest_count").css({ border: "1px solid red !important" });
      vaild_data = false;
    } else {
      setError("");
      $("#guest_count").css({ border: "", background: "" });
    }
    if (1 === 0) {
      if (child_count === "") {
        setError1("Please Enter Number of Child");
        vaild_data = false;
      } else if (isNaN(child_count)) {
        setError1("Please Enter Number of Child");
        vaild_data = false;
      } else if (parseInt(child_count) > 20) {
        setError1(`Please enter a number between 0 and 20.`);
        vaild_data = false;
      } else {
        setError1("");
      }

      if (pet_count === "") {
        setError2("Please Enter Number of Pet");
        vaild_data = false;
      } else if (isNaN(pet_count)) {
        setError2("Please Enter Number of pet");
        vaild_data = false;
      } else if (parseInt(pet_count) > 20) {
        setError2(`Please enter a number between 0 and 20.`);
        vaild_data = false;
      } else {
        setError2("");
      }
    }

    if (vaild_data) {
      setselectedChild(child_count);
      setSelectedGuest(guest_count);
      setselectedpet(pet_count);
      handleclickstep(3, guest_count);
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleCloseKitchen = () => setShowModalKitchen(false);

  const confirmkichan = () => {
    setCurrentStep(3);
    handleCloseKitchen();
  };

  return (
    <>
      <div className="booking_page" id="footer_icons_hide">
        <div
          className="container-fluid booking_page_container col-xl-4 col-lg-5 col-md-6"
          id="hide_bottom_btn"
        >
          <div className="form_div container">
            {/* <div className="col-md-3"></div> */}
            <div className="col-md-12 booking_form">
              <h1>Book a Table</h1>
              <p>Call us : +{phoneno}</p>

              {/* New Form */}
              <div>
                <div className="container calendarTableWrapper">
                  <div
                    className={`iconDiv ${
                      currentStep >= 1 ? "" : "iconDivDisabled"
                    }`}
                  >
                    {currentStep > 1 ? (
                      <div onClick={() => handleclickbackstep(0, "")}>
                        <p>{selectedDate}</p>
                      </div>
                    ) : (
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    )}
                  </div>
                  <div
                    className={`iconDiv ${
                      currentStep >= 2 ? "" : "iconDivDisabled"
                    }`}
                  >
                    {currentStep > 2 ? (
                      <div onClick={() => handleclickbackstep(1, "")}>
                        <p>
                          {selectedTime !== null &&
                            formatTimeintotwodigit(selectedTime)}
                        </p>
                      </div>
                    ) : (
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                    )}
                  </div>
                  <div
                    className={`iconDiv ${
                      currentStep >= 3 ? "" : "iconDivDisabled"
                    }`}
                  >
                    {currentStep > 3 ? (
                      <div onClick={() => handleclickbackstep(2, "")}>
                        <p>{selectedGuest} Guest</p>
                      </div>
                    ) : (
                      <i className="fa fa-user" aria-hidden="true"></i>
                    )}
                  </div>
                  <div
                    className={`iconDiv ${
                      currentStep >= 4 ? "" : "iconDivDisabled"
                    }`}
                  >
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                  </div>
                </div>

                {/* calendar */}
                {currentStep === 1 && (
                  <div>
                    <div className="calendarHeader my-3">
                      {/* Calendar */}
                      <h5>Select Date</h5>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          disablePast
                          onChange={(newValue) => handleclickstep(1, newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}

                {currentStep === 2 && (
                  <div>
                    <div className="timeHeader my-5">
                      <div className="timeWrapper">
                        <div>
                          <div className="timeSlot my-3">
                            {!errorform.error ? (
                              <div className="discount_no_time">
                                "{errormsg}"
                              </div>
                            ) : (
                              <div>
                                <h5>Choose Time</h5>
                                <div className="timeSlot">
                                  {data !== null &&
                                    data.map((item, index) => {
                                      if (item.start_stop_time_status === 0) {
                                        if (
                                          item.online_booking_time_status === 0
                                        ) {
                                          return (
                                            <p
                                              className="m-1"
                                              key={index}
                                              onClick={() =>
                                                handleclickstep(
                                                  2,
                                                  item.primary_id +
                                                    "~@~" +
                                                    item.start_time
                                                )
                                              }
                                            >
                                              {item.per_discount_main > 0 ? (
                                                <span>
                                                  {item.per_discount_main}%
                                                </span>
                                              ) : (
                                                <span>--</span>
                                              )}{" "}
                                              <br />
                                              {formatTimeintotwodigit(
                                                item.start_time
                                              )}
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <p
                                              className="m-1 block_table"
                                              key={index}
                                            >
                                              {item.per_discount_main > 0 ? (
                                                <span>
                                                  {item.per_discount_main}%
                                                </span>
                                              ) : (
                                                <span>--</span>
                                              )}{" "}
                                              <br />
                                              {formatTimeintotwodigit(
                                                item.start_time
                                              )}
                                            </p>
                                          );
                                        }
                                      }
                                    })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {currentStep === 3 && (
                  <div>
                    <div className="timeHeader mt-4">
                      <div className="timeWrapper">
                        <h5>Enter Details</h5>
                        <div className="more_guests_wrapper col-md-11 mt-4 mx-auto">
                          <div className="more_guests_input more_guests_input1 mx-auto">
                            <input
                              type="text"
                              maxLength={3}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              name="guest_count"
                              id="guest_count"
                              placeholder="Number of Guest"
                              className={`trio_mendate form-control trio_count ${
                                error && "show_1px_red"
                              }`}
                            />
                            <p
                              className=" max_guests_error"
                              style={{ color: "red" }}
                            >
                              {error}
                            </p>
                          </div>
                          <div className="more_guests_input more_guests_input2 mx-auto">
                            <input
                              type="text"
                              maxLength={3}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              name="child_count"
                              id="child_count"
                              placeholder="No. of Children"
                            />
                            <p
                              className="max_guests_error"
                              style={{ color: "red" }}
                            >
                              {error1}
                            </p>
                          </div>
                          <div className="more_guests_input more_guests_input3 mx-auto">
                            <input
                              type="text"
                              maxLength={3}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              name="pet_count"
                              id="pet_count"
                              placeholder="No. of Pets"
                            />
                            <p
                              className="max_guests_error"
                              style={{ color: "red" }}
                            >
                              {error2}
                            </p>
                          </div>
                          <div className="next_form_btn mt-3">
                            <button onClick={handleChangeForm} type="submit">
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="more_guests col-11 m-auto"></div> */}
                  </div>
                )}

                {currentStep === 4 && (
                  <div>
                    <div className="userHeader my-5">
                      <div className="validationnnn">
                        <div className="endFormInput1 nameinpttt">
                          <input
                            className={`trio_mendate ${
                              !isValid && "show_1px_red "
                            }`}
                            type="text"
                            placeholder="  Name"
                            name="namecall"
                            id="namecall"
                            maxLength={75}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value
                            //     .replace(/^\s+/, "")
                            //     .replace(/[^A-Za-z\s]/g, "");
                            // }}
                          />
                          {!isValid && (
                            <span className="condition_error">
                              Please Enter Your Name
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="validationnnn PhnVald">
                        <div className="endFormInput2 ">
                          <PhoneInput
                            defaultCountry="se"
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            // className={`${!isValid && "show_1px_red "}`}
                          />
                          {!isValid && (
                            <span className="condition_error">
                              Fill Phone Number
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="validationnnn">
                        <div className="endFormInput3">
                          <input
                            className={`trio_mendate trio_email ${
                              !isValid && "show_1px_red "
                            }`}
                            type="eamil"
                            name="emailcall"
                            id="emailcall"
                            maxLength={100}
                            onInput={(e) => {
                              // if (!/^[a-zA-Z0-9@._]*$/.test(e.target.value)) {
                              //   e.target.value = ""; // Clear the input
                              // }
                            }}
                            placeholder="  Email"
                          />
                          {!isValid && (
                            <span className="condition_error">
                              Please Enter Your Email
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="endFormInput4">
                        <textarea
                          type="text"
                          placeholder="Instruction/ Comment "
                          name="order_comment"
                          maxLength={300}
                          id="order_comment"
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/^\s+/, "")
                              .replace(/[^A-Za-z0-9\s,]/g, "");
                          }}
                        />
                      </div>
                      <div className="Chckbxxx">
                        <input
                          type="checkbox"
                          name="checkSurfaceEnvironment-1"
                          id="checkSurfaceEnvironment-1"
                        />
                        <label htmlFor="checkSurfaceEnvironment-1">
                          I agree to the{" "}
                        </label>
                        <span>
                          <span
                            data-bs-toggle="modal"
                            data-bs-target="#termsModal"
                            style={{ color: "red", cursor: "pointer" }}
                          >
                            &nbsp;Terms & Conditions
                          </span>{" "}
                          <span style={{ color: "black" }}>and</span>
                          <span
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={handlePrivacy}
                          >
                            &nbsp;Privacy Policy
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="row bookBtn">
                      <div className={showLoader ? "loading" : ""}></div>
                      <button
                        className="btn btn-primary"
                        onClick={function_save}
                      >
                        Book
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {/* End New Form */}
              <Modal
                className={`BookingMdl ${showModal ? "fadeInModal" : ""}`}
                centered
                show={showModal}
                onHide={handleClose}
              >
                <Modal.Header closeButton>
                  <div className="suceflllIcon">
                    <Modal.Title>
                      <div className="icnimg">
                        <img src={SuccfllIcon} alt="Image" />
                      </div>
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <p>{modalMessage}</p>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>

        <Modal size="xl" show={showPrivacy} onHide={handlePrivacyClose}>
          <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable m-0">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Privacy Policy
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handlePrivacyClose}
                ></button>
              </div>
              <div className="modal-body">
                <PrivacyPolicy />
              </div>
            </div>
          </div>
        </Modal>

        {/* Kitchen Hours Off Modal */}
        <Modal
          className="kitchenModal"
          show={showModalKitchen}
          centered
          size="lg"
          onHide={handleCloseKitchen}
        >
          <Modal.Body>
            <p>{showModalKitchenMsg}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button className="cancelBtn" onClick={handleCloseKitchen}>
              Cancel
            </Button>
            <Button
              className="ConfirmBtn"
              onClick={() => {
                confirmkichan();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
export default BookingPage;
