import React, { useState, useEffect } from "react";
import "../Css/Test.css";
import { useInView } from 'react-intersection-observer';
import Beerlogo1 from "../Assets/BeerLogos/01.png";
import Beerlogo2 from "../Assets/BeerLogos/02.png";
import Beerlogo3 from "../Assets/BeerLogos/03.png";
import Beerlogo4 from "../Assets/BeerLogos/04.png";
import Beerlogo5 from "../Assets/BeerLogos/05.png";
import Beerlogo6 from "../Assets/BeerLogos/06.png";
import Beerlogo7 from "../Assets/BeerLogos/07.png";
import Beerlogo8 from "../Assets/BeerLogos/08.png";
import Beerlogo9 from "../Assets/BeerLogos/09.png";
import Beerlogo10 from "../Assets/BeerLogos/10.png";
import Beerlogo11 from "../Assets/BeerLogos/11.png";
import Beerlogo12 from "../Assets/BeerLogos/12.png";
import Beerlogo13 from "../Assets/BeerLogos/13.png";
import Beerlogo14 from "../Assets/BeerLogos/14.png";
import Beerlogo15 from "../Assets/BeerLogos/15.png";
import Beerlogo16 from "../Assets/BeerLogos/16.png";
import Beerlogo17 from "../Assets/BeerLogos/17.png";
import Beerlogo18 from "../Assets/BeerLogos/18.png";

function Test() {
  
  const imageUrls = [
    Beerlogo1,
    Beerlogo2,
    Beerlogo3,
    Beerlogo4,
    Beerlogo5,
    Beerlogo6,
    Beerlogo7,
    Beerlogo8,
    Beerlogo9,
    Beerlogo10,
    Beerlogo11,
    Beerlogo12,
    Beerlogo13,
    Beerlogo14,
    Beerlogo15,
    Beerlogo16,
    Beerlogo17,
    Beerlogo18,
  ];

  const imageHeadings = [
    "Heading 1",
    "Heading 2",
    "Heading 3",
    "Heading 4",
    "Heading 5",
    "Heading 6",
    "Heading 7",
    "Heading 8",
    "Heading 9",
    "Heading 10",
    "Heading 11",
    "Heading 12",
    "Heading 13",
    "Heading 14",
    "Heading 15",
    "Heading 16",
    "Heading 17",
    "Heading 18",
  ];

  const [currentGroup, setCurrentGroup] = useState(1);
  const imagesPerGroup = 5; // Number of images to display in each group

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGroup((prevGroup) => (prevGroup % 4) + 1);
    }, 8000); // Total animation duration (8s each group) * 3
    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

  // Calculate the starting index for the current group
  const startIndex = (currentGroup - 1) * imagesPerGroup;

  // Create a slice of image URLs for the current group
  const currentGroupImages = [];

  for (let i = 0; i < imagesPerGroup; i++) {
    const index = (startIndex + i) % imageUrls.length;
    currentGroupImages.push(imageUrls[index]);
  }

  function ImageWithHeading({ imageUrl, heading }) {
    const [ref, inView] = useInView({
      triggerOnce: true,
    });
  
    return (
      <div ref={ref} className="logoImg_wrapper col-2 m-auto">
        <img src={imageUrl} alt={`beer logo`} />
        {inView && <p className="image-heading">{heading}</p>}
      </div>
    );
  }
  return (
    // <section className="beer_logo_caps mt-4 mx-auto">
    //   <div className="beer_logo_container">
    //     <div className={`image_group group${currentGroup}`}>
    //       {currentGroupImages.map((imageUrl, index) => (
    //         <div key={index} className="logoImg_wrapper col-2 m-auto">
    //           <img src={imageUrl} alt={`beer logo ${index + 1}`} />
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </section>
    <section className="beer_logo_caps mt-4 mx-auto">
      <div className="beer_logo_container">
        <div className={`image_group group${currentGroup}`}>
          {currentGroupImages.map((imageUrl, index) => (
            <ImageWithHeading
              key={index}
              imageUrl={imageUrl}
              heading={imageHeadings[(startIndex + index) % imageUrls.length]}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Test;
